import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//Import dos styles do template
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import '@/assets/css/font-awesome.min.css';
import '@/assets/css/icon-font.min.css';
import '@/assets/css/plugins.css';
import '@/assets/css/main.css';


// import "@/assets/js/vendor/jquery.min.js"
// import "@/assets/js/popper.min.js"
// import "@/assets/js/plugins.js"
// import "@/assets/js/main.js"

createApp(App).use(store).use(router).mount('#app')
