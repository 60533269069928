<template>
  <section class="product-page" v-if="especificacoes.length">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-container">
            <div class="block-title">
              <h2 class="specs-title">ESPECIFICAÇÕES</h2>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" :class="{ 'show active': tabs.activeTab === 'specs' }" role="tabpanel">
                <div v-if="isLoading">
                  <EspecificacoesSkeleton />
                </div>
                <div v-else>
                  <table class="data-table">
                    <tbody>
                      <tr v-for="(spec, index) in especificacoes" :key="index">
                        <td class="spec-name">{{ spec.nome }}</td>
                        <td class="spec-value">{{ spec.valor || '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import EspecificacoesSkeleton from './EspecificacoesSkeleton.vue';

const route = useRoute();
const slug = route.params.slug;

const tabs = ref({
  activeTab: 'specs'
});

const especificacoes = ref([]);
const isLoading = ref(true);

const fetchProductData = async () => {
  try {
    const response = await axios.get(`https://api-genove.agcodecraft.com/api/public/products/${slug}/details`);
    const data = response.data;

    especificacoes.value = data.especificacoes || []; 

  } catch (error) {
    console.error('Erro ao buscar os dados do produto:', error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchProductData();
});

const setActiveTab = (tab) => {
  tabs.value.activeTab = tab;
};
</script>


<style scoped>
.product-page {
  padding: 40px 0;
}

.tab-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.block-title {
  margin-bottom: 20px;
}

.specs-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table tbody {
  display: flex;
  flex-direction: column;
}

.data-table tbody tr {
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s;
}

.data-table tbody tr:hover {
  background-color: #f5f5f5;
}

.data-table td {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
  flex: 1;
}

.data-table tr:nth-child(odd) td {
  background-color: #fafafa;
}

.data-table td:first-child {
  font-weight: 500;
  color: #444;
}

.data-table td:last-child {
  text-align: right;
  font-weight: 600;
  color: #333;
}

@media (max-width: 768px) {
  .product-page {
    padding: 20px 10px;
  }

  .tab-container {
    padding: 10px;
  }

  .specs-title {
    font-size: 1.25rem;
  }

  .data-table {
    display: block;
    width: 100%;
  }

  .data-table tbody {
    display: block;
  }

  .data-table tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
  }

  .data-table td {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    padding: 8px 0;
  }

  .data-table td:first-child {
    font-weight: 700;
    color: #444;
    border-bottom: 1px solid #ddd;
  }

  .data-table td:last-child {
    font-weight: 400;
    border-top: 1px solid #ddd;
  }
}

@media (max-width: 480px) {
  .product-page {
    padding: 10px 5px;
  }

  .tab-container {
    padding: 8px;
  }

  .specs-title {
    font-size: 1rem;
  }

  .data-table td {
    font-size: 0.75rem;
    padding: 8px;
  }
}
</style>
