<template>
  <div class="container my-5">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="mb-0">DESTAQUES</h2>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary btn-sm me-2"
          type="button"
          @click="moveLeft"
          aria-label="Move to previous slide"
          :disabled="loading"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          @click="moveRight"
          aria-label="Move to next slide"
          :disabled="loading"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>

    <div class="linha-abaixo-titulo"></div>

    <div id="featuredProductsCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          class="carousel-item"
          :class="{ active: index === currentIndex }"
          v-for="(group, index) in groupedProdutos"
          :key="index"
        >
          <div class="carousel-item-content">
            <router-link
              v-for="produto in group"
              :key="produto.slug"
              :to="`/produto/${produto.slug}`"
              class="product-item"
            >
              <div class="product-image-container">
                <img 
                  :src="produto.imagens && produto.imagens.length > 0 ? produto.imagens[0].url : semImagem" 
                  class="product-image" 
                  :alt="produto.nome" 
                />
              </div>
              <div class="product-info">
                <p class="product-name">{{ produto.nome }}</p>
                <p class="product-price">{{ formatarPreco(produto.valor_venda) }}</p>
                <p class="product-code">{{ produto.codigo }}</p>
              </div>
            </router-link>
          </div>
        </div>

        <!-- Skeleton placeholder -->
        <div
          v-if="loading"
          class="carousel-item active"
        >
          <div class="carousel-item-content">
            <div v-for="n in produtosPorSlide" :key="n" class="product-item skeleton">
              <div class="product-image-container">
                <div class="product-image skeleton-image"></div>
              </div>
              <div class="product-info">
                <p class="product-name skeleton-text"></p>
                <p class="product-price skeleton-text"></p>
                <p class="product-code skeleton-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useWindowSize } from '@vueuse/core';
import axios from 'axios';
import 'bootstrap';
import { Carousel } from 'bootstrap';
import semImagem from '../../assets/images/produto-sem-imagem.jpg';

const produtosDestaque = ref([]);
const loading = ref(true);
const { width } = useWindowSize();

const produtosPorSlide = computed(() => {
  if (width.value >= 1600) return 4;
  if (width.value >= 1200) return 3;
  if (width.value >= 768) return 2;
  return 1;
});

const groupedProdutos = computed(() => {
  const numProdutos = produtosPorSlide.value;
  return Array.from({ length: Math.ceil(produtosDestaque.value.length / numProdutos) }, (_, index) => 
    produtosDestaque.value.slice(index * numProdutos, (index + 1) * numProdutos)
  );
});

const currentIndex = ref(0);
let carouselInstance;

const fetchFeaturedProducts = async () => {
  if (loading.value) { 
    try {
      const response = await axios.get('https://api-genove.agcodecraft.com/api/public/products?brand_id=3');
      produtosDestaque.value = response.data.data;
    } catch (error) {
      console.error('Erro ao buscar produtos destacados:', error);
    } finally {
      loading.value = false; 
    }
  }
};



onMounted(() => {
  fetchFeaturedProducts();

  const carouselElement = document.getElementById('featuredProductsCarousel');
  carouselInstance = new Carousel(carouselElement, {
    interval: false,
    wrap: false,
    ride: false,
    slide: false 
  });

  const resizeObserver = new ResizeObserver(() => {
    updateCarousel();
  });
  
  resizeObserver.observe(carouselElement);

  window.addEventListener('resize', updateCarousel);
});

const updateCarousel = () => {
  groupedProdutos.value = [...groupedProdutos.value];
  carouselInstance.dispose();
  const carouselElement = document.getElementById('featuredProductsCarousel');
  carouselInstance = new Carousel(carouselElement, {
    interval: false,
    wrap: false,
    ride: false,
    slide: false 
  });
};

const moveLeft = () => {
  if (!loading.value) {
    carouselInstance.prev();
    currentIndex.value = Math.max(currentIndex.value - 1, 0);
    disableButtonsTemporarily();
  }
};

const moveRight = () => {
  if (!loading.value) {
    carouselInstance.next();
    currentIndex.value = Math.min(currentIndex.value + 1, groupedProdutos.value.length - 1);
    disableButtonsTemporarily();
  }
};

const disableButtonsTemporarily = () => {
  const buttons = document.querySelectorAll('.btn-outline-primary');
  buttons.forEach(button => button.disabled = true);
  
  setTimeout(() => {
    buttons.forEach(button => button.disabled = false);
  }, 600); // Ajuste o tempo conforme necessário para se adequar à duração da animação
};


const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  if (isNaN(precoNumerico)) return 'Preço inválido';
  
  // Formata o preço para usar vírgula como separador decimal
  const precoFormatado = precoNumerico.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return `U$ ${precoFormatado}`;
};


</script>


<style scoped>
h2 {
  font-size: 2rem;
  color: #EE9E4F;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
}

.btn-outline-primary {
  color: #777777;
  border-color: #777777;
}

.btn-outline-primary:hover {
  background-color: #C99C5A;
  color: white;
  border-color: #C99C5A;
}

.carousel-item-content {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: nowrap;
}

.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  flex: 1 1 auto;
  max-width: 25%;
  min-width: 220px;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 1.2rem;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
}

.product-price {
  font-size: 1.5rem;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 5px 0;
  font-weight: 700;
  margin-top: 10px;
}

.product-code {
  font-size: 0.9rem;
  color: #757575;
  line-height: 1.667;
  margin-top: auto;
  margin-bottom: 15px;
}

/* Estilos do Skeleton */
.skeleton {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
    to right,
    #eeeeee 4%,
    #dddddd 25%,
    #eeeeee 36%
  );
  background-size: 1000px 100%;
  position: relative;
  overflow: hidden;
}

.skeleton-image {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.skeleton-text {
  background-color: #eeeeee;
  height: 1rem;
  width: 70%;
  margin: 0 auto;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
