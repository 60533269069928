<template>
  <nav class="header-navigation">
    <div class="container px-0">
      <div class="navigation-container mb-0">
        <div class="row">
          <div class="col-lg-3 d-none d-lg-block">
          </div>
          <div class="col-lg-9 col-md-12">
            <div class="main-menu">
              <nav>
                <ul>
                  <li class="menu-item-has-children">
                    <a href="#" @click.prevent="toggleMenu('categories')">Categorias</a>
                    <ul class="sub-menu">
                      <li v-for="category in departments" :key="category.id" class="menu-item-has-children">
                        <a href="#" @click.prevent="selectCategory(category)">{{ category.name }}</a>
                        <ul class="sub-menu">
                          <li v-for="department in category.departments" :key="department.id">
                            <a href="#" @click.prevent="selectDepartment(department)">{{ department.name }}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li><a href="#" @click.prevent="selectBrand(5, 'Apple')">Apple</a></li>
                  <li><a href="#" @click.prevent="selectBrand(3, 'Xiaomi')">Xiaomi</a></li>
                  <li><a href="#" @click.prevent="selectBrand(6, 'Honor')">Honor</a></li>
                  <li><a href="#" @click.prevent="selectBrand(4, 'XO')">XO</a></li>
                </ul>
              </nav>
              <div class="mobile-menu d-block d-lg-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'HeaderNavigation',
  data() {
    return {
      departments: [],
      menuOpen: false,
    };
  },
  async created() {
    try {
      const response = await axios.get('https://api-genove.agcodecraft.com/api/public/categories');
      this.departments = response.data.map(category => ({
        ...category,
        departments: category.departments ? [...category.departments].sort((a, b) => a.name.localeCompare(b.name)) : []
      })).sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Erro ao buscar os departamentos:', error);
    }
  },
  methods: {
    toggleMenu(type) {
      this.menuOpen = !this.menuOpen;
      this.$emit('menu-toggle', type);
    },
    selectCategory(category) {
      this.$emit('filter-change', { categoryId: category.id });
      this.$router.push({ name: 'produtos', query: { category: category.id } });
    },
    selectDepartment(department) {
      this.$emit('filter-change', { departmentId: department.id });
      this.$router.push({ name: 'produtos', query: { department: department.id } });
    },
    selectBrand(brandId, brandName) {
      this.$emit('filter-change', { brandId: brandId });
      this.$router.push({ name: 'produtos', query: { brand: brandId, brandName: brandName } });
    }



  },
  setup() {
    const router = useRouter();
    return { router };
  }
};
</script>

<style scoped>
.header-navigation {
  background-color: #000000;
  padding: 0 20px;
  align-items: flex-start; 
}

.header-navigation .container {
  max-width: 1500px;
  padding: 0;
}

.header-navigation .main-menu ul li a {
  font-size: 20px;
}


.header-navigation .sub-menu li a {
  font-size: 18px;
}

.navigation-container {
  padding: 8px 0;
}


.main-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  height: 50px;
  align-items: flex-end; 
  
}

.main-menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-menu ul li {
  position: relative;
}

.main-menu ul li a {
  position: relative;
  padding: 15px 25px;
  font-size: 18px;
  text-decoration: none;
  color: #b6b6b6;
  display: inline-block;
}

.main-menu ul li a::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3px;
  background-color: #C99C5A;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: top left;
}

.main-menu ul li a:hover::before {
  transform: scaleX(1);
}

.menu-item-has-children>a::after {
  content: ' ▼';
  font-size: 14px;
  color: #b6b6b6;
  margin-left: 7px;
  
}
.menu-item {
  position: relative;
  
}

.menu-item-has-children {
  position: relative;
  
  
}

.menu-item-has-children>a::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #C99C5A;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: top left;
  
}

.menu-item-has-children:hover>a::before {
  transform: scaleX(1);
  
}

.menu-item-has-children:hover .sub-menu {
  display: block;
  
} 
.menu-item-has-children .sub-menu {
  display: block;
  
} 

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; 
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  
}

.sub-menu li {
  border-bottom: 1px solid #ddd;
  list-style: none; 
}

.sub-menu li:last-child {
  border-bottom: none;
}


.sub-menu li a {
  
  font-size: 16px;
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  width: 100%; 
  padding: 10px 15px;
}


.sub-menu li a:hover {
  background-color: #adadad; 
  width: 100%;
}



.menu-toggle {
  background: none;
  border: none;
  color: #b6b6b6;
  font-size: 28px;
  cursor: pointer;
}

.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
}

.side-menu.menu-open {
  left: 0;
}

.close-menu {
  background: none;
  border: none;
  font-size: 48px;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 30px;
}

.side-menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 20px;
}

.side-menu ul li a:hover {
  color: #C99C5A;
}

@media (max-width: 991px) {
  .header-navigation {
    background-color: #ffffff;
  }

  .main-menu ul {
    display: block;
  }

  .main-menu ul li {
    margin-right: 0;
  }

  .main-menu ul li a {
    padding: 15px;
    font-size: 18px;
    color: #333333;
  }

  .main-menu ul li a:hover {
    color: #000000;
  }
}
</style>