<template>
  <div class="carousel-container">
    <div class="carousel">
      <!-- Slides -->
      <div class="carousel-slides" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
        <div
          v-for="(item, index) in slides"
          :key="index"
          class="carousel-slide"
          @click="navigateToProduct(index)"
        >
          <div class="carousel-image-wrapper">
            <img :src="item.image" :alt="'Slide ' + (index + 1)" class="carousel-image" />
          </div>
        </div>
      </div>
    </div>

    <!-- Indicadores de slide -->
    <div class="carousel-indicators">
      <div
        v-for="(item, index) in slides"
        :key="'indicator-' + index"
        :class="['indicator', { active: index === currentSlide }]"
        aria-label="'Slide ' + (index + 1)"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const slides = ref([
  { image: require('@/assets/images/BannerLateral03.png') },
  { image: require('@/assets/images/BannerLateral04.png') },
]);


const slideLinks = ref([
  'fone-bt-earphone-xo-g11-sword-rain-gaming-30533',
  'estacao-de-energia-xo-psa-1200-384000mah1229wh1200w-30298'  
]);

const currentSlide = ref(0);
const slideInterval = ref(null);
const router = useRouter();

const nextSlide = () => {
  currentSlide.value = (currentSlide.value + 1) % slides.value.length;
};

const prevSlide = () => {
  currentSlide.value = (currentSlide.value - 1 + slides.value.length) % slides.value.length;
};

const startAutoSlide = () => {
  slideInterval.value = setInterval(nextSlide, 5000);
};

const stopAutoSlide = () => {
  if (slideInterval.value) {
    clearInterval(slideInterval.value);
  }
};

// Navega para o produto correspondente ao slide clicado
const navigateToProduct = (slideIndex) => {
  const slug = slideLinks.value[slideIndex];
  if (slug) {
    router.push({ name: 'produto', params: { slug } });
  }
};

onMounted(() => {
  startAutoSlide();
});

onUnmounted(() => {
  stopAutoSlide();
});
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 250px;
  height: 500px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.carousel {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.carousel-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 12px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: absolute;
  bottom: 10px;
  z-index: 10;
}

.indicator {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  height: 4px;
  width: 30px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 2px;
}

.indicator.active {
  background-color: #ffffff;
}
</style>
