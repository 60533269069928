<template>
  <div class="custom-homepage-carousels">
    <div
      v-for="(banner, index) in banners"
      :key="index"
      class="custom-carousel-container"
    >
      <div class="custom-carousel-wrapper" :style="{ transform: `translateX(-${currentSlide[index] * 100}%)` }">
        <div
          v-for="(item, itemIndex) in banner.images"
          :key="itemIndex"
          class="custom-carousel-item"
        >
          <a
            @click.prevent="navigateToItem(index, itemIndex)"
          >
            <div class="custom-carousel-image-container">
              <img :src="item.image" :alt="'Banner ' + (itemIndex + 1)">
            </div>
          </a>
        </div>
      </div>

      <div class="custom-carousel-indicators">
        <div
          v-for="(item, itemIndex) in banner.images"
          :key="'indicator-' + itemIndex"
          :class="{ active: currentSlide[index] === itemIndex }"
          @click="goToSlide(index, itemIndex)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import bannerImage1 from '@/assets/images/BannerCentral01.jpg';
import bannerImage2 from '@/assets/images/BannerCentral02.jpg';
import bannerImage3 from '@/assets/images/BannerCentral03.jpg';
import bannerImage4 from '@/assets/images/BannerCentral04.jpg';
import bannerImage5 from '@/assets/images/BannerCentral05.jpg';
import bannerImage6 from '@/assets/images/BannerCentral06.jpg';

const banners = ref([
  {
    images: [
      { image: bannerImage1 },
      { image: bannerImage2 },
    ]
  },
  {
    images: [
      { image: bannerImage3 },
      { image: bannerImage4 },
    ]
  },
  {
    images: [
      { image: bannerImage5 },
      { image: bannerImage6 },
    ]
  },
]);

const bannerLinks = ref([
  [
    'smartwatch-xo-j4-amoled-black-28929',
    '33107-sony-playstation-portal-cfi-y1001'
  ],
  [
    'console-nintendo-switch-oled-64gb-jp-white-25102',
    '35231-sony-playstation-5-slim-1tb-cfi-2000-a01-jp-disc-2-controles'
  ],
  [
    'apple-vision-pro',  // Identificador especial para Apple Vision Pro
    'oculos-xo-e8-audio-sunglasses-orangewhite'
  ]
]);

const currentSlide = ref(Array(banners.value.length).fill(0));
const router = useRouter();

const navigateToProduct = (text) => {
  router.push({ name: 'produtos', query: { text } });
};

const navigateToItem = (bannerIndex, itemIndex) => {
  // Verifica se o item é o banner do Apple Vision Pro
  if (bannerIndex === 2 && itemIndex === 0) {
    const text = 'apple vision pro'; // Texto para busca no Apple Vision Pro
    navigateToProduct(text);
  } else {
    const slug = bannerLinks.value[bannerIndex][itemIndex];
    if (slug) {
      router.push({ name: 'produto', params: { slug } });
    }
  }
};

const goToSlide = (index, slideIndex) => {
  currentSlide.value[index] = slideIndex;
};

const autoSlide = () => {
  banners.value.forEach((banner, index) => {
    const slides = banner.images.length;
    currentSlide.value[index] = (currentSlide.value[index] + 1) % slides;
  });
};

let intervalId = null;

onMounted(() => {
  intervalId = setInterval(autoSlide, 5000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>


<style scoped>
.custom-homepage-carousels {
  display: grid;
  gap: 20px;
  padding: 20px 0;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.custom-carousel-container {
  position: relative;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.custom-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.custom-carousel-item {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
}

.custom-carousel-image-container {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.custom-carousel-image-container img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease-in-out;
}

.custom-carousel-item:hover .custom-carousel-image-container img {
  transform: scale(1.05);
}

.custom-carousel-indicators {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.custom-carousel-indicators div {
  background-color: white;
  border-radius: 0;
  width: 30px;
  height: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-carousel-indicators div.active {
  background-color: black;
}

@supports (display: grid) {
  @media (max-width: 991px) {
    .custom-homepage-carousels {
      grid-template-columns: 1fr;
    }
  }
}

</style>
