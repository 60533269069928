<template>
  <div :class="['side-menu', { 'menu-open': menuOpen }]">
    <button class="back-button" @click="closeMenu">&larr;</button>
    <nav>
      <ul>
        <!-- Destaque para as marcas, posicionadas acima -->
        <li class="brand-link">
          <a href="#" @click.prevent="handleSelection(() => selectBrand(5))">Apple</a>
        </li>
        <li class="brand-link">
          <a href="#" @click.prevent="handleSelection(() => selectBrand(3))">Xiaomi</a>
        </li>
        <li class="brand-link">
          <a href="#" @click.prevent="handleSelection(() => selectBrand(6))">Honor</a>
        </li>
        <li class="brand-link">
          <a href="#" @click.prevent="handleSelection(() => selectBrand(4))">XO</a>
        </li>

        <!-- Lista de categorias e departamentos abaixo -->
        <li v-for="category in departments" :key="category.id" class="menu-item-has-children">
          <a href="#" @click.prevent="handleSelection(() => selectCategory(category))" class="category-link">{{ category.name }}</a>
          <ul class="sub-menu">
            <li v-for="department in category.departments" :key="department.id">
              <a href="#" @click.prevent="handleSelection(() => selectDepartment(department))" class="department-link">{{ department.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'MobileMenu',
  props: {
    menuOpen: Boolean
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const departments = ref([]);

    const loadCategories = () => {
      const storedCategories = localStorage.getItem('categories');
      if (storedCategories) {
        departments.value = JSON.parse(storedCategories).map(category => ({
          ...category,
          departments: category.departments ? [...category.departments].sort((a, b) => a.name.localeCompare(b.name)) : []
        })).sort((a, b) => a.name.localeCompare(b.name));
      } else {
        // Fazer chamada para a API se não houver categorias no localStorage
        fetchCategories();
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await store.dispatch('fetchCategories');
        departments.value = response.map(category => ({
          ...category,
          departments: category.departments ? [...category.departments].sort((a, b) => a.name.localeCompare(b.name)) : []
        })).sort((a, b) => a.name.localeCompare(b.name));

        // Armazenar no localStorage
        localStorage.setItem('categories', JSON.stringify(departments.value));
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    const closeMenu = () => {
      emit('toggle-menu');
    };

    const handleSelection = (action) => {
      action();
      closeMenu();
    };

    const selectCategory = (category) => {
      emit('filter-change', { categoryId: category.id });
      router.push({ name: 'produtos', query: { category: category.id } });
    };

    const selectDepartment = (department) => {
      emit('filter-change', { departmentId: department.id });
      router.push({ name: 'produtos', query: { department: department.id } });
    };

    const selectBrand = (brandId) => {
      emit('filter-change', { brandId: brandId });
      router.push({ name: 'produtos', query: { brand: brandId } });
    };

    onMounted(() => {
      loadCategories();
    });

    return {
      departments,
      closeMenu,
      handleSelection,
      selectCategory,
      selectDepartment,
      selectBrand
    };
  }
};
</script>



<style scoped>
.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 90%;
  height: 100%;
  background-color: #fff; /* Cor de fundo clara */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
  color: #333; /* Texto escuro */
}

.side-menu.menu-open {
  left: 0;
}

.back-button {
  background: none;
  border: none;
  font-size: 32px;
  color: #333; /* Cor escura para o botão de voltar */
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px; /* Posiciona o botão no canto esquerdo */
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.brand-link a {
  text-decoration: none;
  color: #C99C5A; /* Cor de destaque para as marcas */
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.2); /* Fundo claro para destaque */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.brand-link a:hover {
  background-color: #C99C5A; /* Fundo ao passar o mouse */
  color: #fff; /* Texto branco ao passar o mouse */
}

.category-link {
  text-decoration: none;
  color: #333; /* Texto escuro para categorias */
  font-size: 20px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.1);
  border-left: 4px solid #C99C5A;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.category-link:hover {
  background-color: #C99C5A;
  color: #fff; /* Texto branco ao passar o mouse */
}

.department-link {
  text-decoration: none;
  color: #666; /* Texto cinza para departamentos */
  font-size: 16px;
  display: block;
  padding: 8px 20px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  margin-left: 15px;
}

.department-link:hover {
  background-color: rgba(201, 156, 90, 0.2);
  color: #333; /* Texto mais escuro ao passar o mouse */
}

.side-menu .sub-menu {
  padding-left: 0;
  display: none;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}

.menu-item-has-children:hover .sub-menu {
  display: block;
  max-height: 200px;
}

.menu-item-has-children a::after {
  content: '▼';
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s;
}

.menu-item-has-children:hover a::after {
  transform: rotate(-180deg);
}
</style>




<!-- ESTILO DARK
<style scoped>
.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 90%;
  height: 100%;
  background-color: #333;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
  color: #fff;
}

.side-menu.menu-open {
  left: 0;
}

.close-menu {
  background: none;
  border: none;
  font-size: 32px;
  color: #C99C5A;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.brand-link a {
  text-decoration: none;
  color: #C99C5A; /* Cor de destaque para as marcas */
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.2); /* Fundo diferente para destaque */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.brand-link a:hover {
  background-color: #C99C5A; /* Fundo ao passar o mouse */
  color: #333; /* Texto mais escuro ao passar o mouse */
}

.category-link {
  text-decoration: none;
  color: #C99C5A;
  font-size: 20px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.1);
  border-left: 4px solid #C99C5A;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.category-link:hover {
  background-color: #C99C5A;
  color: #333;
}

.department-link {
  text-decoration: none;
  color: #C99C5A;
  font-size: 16px;
  display: block;
  padding: 8px 20px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  margin-left: 15px;
}

.department-link:hover {
  background-color: rgba(201, 156, 90, 0.2);
  color: #333;
}

.side-menu .sub-menu {
  padding-left: 0;
  display: none;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}

.menu-item-has-children:hover .sub-menu {
  display: block;
  max-height: 200px;
}

.menu-item-has-children a::after {
  content: '▼';
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s;
}

.menu-item-has-children:hover a::after {
  transform: rotate(-180deg);
}
</style>
 -->