// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProdutoView from '../views/ProdutoView.vue';
import ProdutosView from '../views/ProdutosView.vue';
import VendedoresView from '../views/VendedoresView.vue';
import MarcaProdutosView from '../views/MarcaProdutosView.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/produto/:slug?',
    name: 'produto',
    component: ProdutoView,
    props: true
  },
  {
    path: '/produtos',
    name: 'produtos',
    component: ProdutosView,
    props: route => ({
      text: route.query.text,
      brand: route.query.brand_id,
      category: route.query.category_id,
      department: route.query.department_id
    })
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: VendedoresView,
    props: true
  },
  {
    path: '/marca',
    name: 'marca',
    component: MarcaProdutosView,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    store.dispatch('addRoute', from.fullPath);
  }
  next();
});

export default router;
