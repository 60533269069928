<template>
  <div class="product-page">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="category-sidebar">
      <ul class="category-list">
        <li v-for="(category, index) in categories" :key="index" class="category-item"
          @mouseenter="hoveredCategory = category.id" @mouseleave="hoveredCategory = null">
          <a href="#" class="category-link" @click.prevent="selectCategory(category)">
            <span class="category-name">{{ category.name }}</span>
          </a>
          <transition name="fade">
            <ul v-if="hoveredCategory === category.id" class="department-list">
              <li v-for="(department, deptIndex) in category.departments" :key="deptIndex">
                <a href="#" class="department-link" @click.prevent="selectDepartment(department)">
                  {{ department.name }}
                </a>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>

    <!-- Lista de Produtos -->
    <div class="product-list-section">
      <div class="header">
        <h2>{{ selectedTitle }}</h2>
        <div class="sort-options">
          <div class="custom-select-wrapper">
            <select id="sort" v-model="sortOption">
              <option value="">Ordenar por</option>
              <option value="valor_venda:asc">Preço Menor</option>
              <option value="valor_venda:desc">Preço Maior</option>
              <option value="nome:asc">Nome: A-Z</option>
              <option value="nome:desc">Nome: Z-A</option>
            </select>
          </div>
        </div>


      </div>
      <div class="linha-abaixo-titulo"></div>
      <div v-if="sortedProducts.length === 0">
        <p>Nenhum produto disponível para esta categoria.</p>
      </div>
      <div v-else class="product-list">
        <router-link v-for="(product, index) in sortedProducts" :key="index" :to="`/produto/${product.slug}`"
          class="product-item">
          <div class="product-image-container">
            <img :src="product.imagens[0]?.url || defaultImage" :alt="`Imagem do produto ${product.nome}`"
              class="product-image">
          </div>
          <div class="product-info">
            <p class="product-name">{{ product.nome }}</p>
            <p class="product-price">{{ formatarPreco(product.valor_venda) }}</p>
            <p class="product-code">{{ product.codigo }}</p>
          </div>
        </router-link>
      </div>
      <div class="pagination-controls">
        <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Próxima</button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from '@/components/template/loading-spinner.vue';

const categories = ref([]);
const filteredProducts = ref([]);
const selectedTitle = ref('Produtos');
const brandName = ref('');
const sortOption = ref('');
const defaultImage = require('../assets/images/produto-sem-imagem.jpg');
const error = ref(null);
const selectedCategoryId = ref(null);
const selectedBrand = ref(null);
const selectedDepartmentId = ref(null);
const searchText = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalProducts = ref(0);
const isLoading = ref(false);
const hoveredCategory = ref(null);
const activeCategory = ref(null);
const activeDepartment = ref(null);

const goToPage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
    fetchProducts(page);
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    goToPage(currentPage.value + 1);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    goToPage(currentPage.value - 1);
  }
};

const route = useRoute();
const router = useRouter();

const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  return isNaN(precoNumerico) ? 'Preço inválido' : `US$ ${precoNumerico.toFixed(2).replace('.', ',')}`;
};

const selectCategory = async (category) => {
  selectedCategoryId.value = category.id;
  selectedDepartmentId.value = null;
  selectedTitle.value = category.name;
  activeCategory.value = category.id;
  activeDepartment.value = null;
  await fetchProducts(1);
  router.push({ query: { category: category.id } });
};

const selectDepartment = async (department) => {
  selectedDepartmentId.value = department.id;
  selectedTitle.value = department.name;
  activeDepartment.value = department.id;
  await fetchProducts(1);
  router.push({ query: { department: department.id } });
};

const selectBrand = async (brand) => {
  selectedBrand.value = brand.id;
  brandName.value = brand.name; 
  selectedTitle.value = brand.name;
  await fetchProducts(1);
  router.push({ query: { brand: brand.id } });
};
const sortedProducts = computed(() => filteredProducts.value);

const fetchCategories = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get('https://api-genove.agcodecraft.com/api/public/categories');

    const sortedCategories = response.data.sort((a, b) => a.name.localeCompare(b.name));

    sortedCategories.forEach(category => {
      if (category.departments) {
        category.departments.sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    categories.value = sortedCategories;
  } catch (err) {
    error.value = 'Erro ao buscar categorias e departamentos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};


const fetchProducts = async (page = 1) => {
 
  currentPage.value = page;

  isLoading.value = true;
  window.scrollTo(0, 0); 
  try {
    
    const [orderBy, order] = sortOption.value.split(':');
    const orderByParam = orderBy ? `order_by=${orderBy}` : '';
    const orderParam = order ? `order=${order}` : '';

  
    const params = {
      text: searchText.value,
      category_id: selectedCategoryId.value,
      department_id: selectedDepartmentId.value,
      brand_id: selectedBrand.value,
      page: currentPage.value, 
    };

    let queryString = "";
    Object.keys(params).forEach((key) => {
      if (params[key] != null && params[key] != undefined && params[key] !== "") {
        if (queryString.length > 0) queryString += "&";
        queryString += key + "=" + encodeURIComponent(params[key]);
      }
    });

    if (orderByParam) queryString += (queryString.length > 0 ? "&" : "") + orderByParam;
    if (orderParam) queryString += (queryString.length > 0 ? "&" : "") + orderParam;

    const response = await axios.get(`https://api-genove.agcodecraft.com/api/public/products?${queryString}`);
    filteredProducts.value = response.data.data || [];
    totalProducts.value = response.data.total;
    totalPages.value = Math.ceil(totalProducts.value / 20);
  } catch (err) {
    error.value = 'Erro ao buscar produtos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};





watch(() => route.query.text, (text) => {
  searchText.value = text || '';
  fetchProducts();
});

watch(() => route.query.category, (category) => {
  selectedCategoryId.value = category;
  activeCategory.value = category;
  fetchProducts();
});

watch(() => route.query.department, (department) => {
  selectedDepartmentId.value = department;
  activeDepartment.value = department;
  fetchProducts();
});

watch(() => route.query.brand, async (brandId) => {
  selectedBrand.value = brandId;
  const brand = categories.value.flatMap(c => c.departments || []).find(dept => dept.id === Number(brandId));
  selectedTitle.value = route.query.brandName || (brand ? brand.name : 'Produtos');
  await fetchProducts();
});

watch(sortOption, () => {
  fetchProducts();
});
watch(brandName, (newBrandName) => {
  document.title = newBrandName ? `Produtos - ${newBrandName}` : 'Produtos';
});

onMounted(() => {
  fetchCategories();

  searchText.value = route.query.text || "";
  selectedCategoryId.value = route.query.category || "";
  selectedDepartmentId.value = route.query.department || "";
  selectedBrand.value = route.query.brand || "";

  activeCategory.value = route.query.category || null;
  activeDepartment.value = route.query.department || null;

  fetchProducts();
});
</script>



<style scoped>
html, body {
  overflow-x: hidden; 
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  
}
.container {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.product-page {
  display: flex;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  max-width: 1500px; 
  margin: 0 auto;
  
}

.category-sidebar {
  width: 280px;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  position: relative;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.category-item {
  position: relative;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #ffffff;
  border: 1px solid transparent;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
}

.category-link:hover,
.category-link.active {
  background-color: #C99C5A;
  color: #ffffff;
  border-color: #C99C5A;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.category-link i {
  transition: transform 0.3s ease;
}

.department-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 100%;
  top: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item:hover .department-list {
  display: block;
  opacity: 1;
}

.department-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.department-link:hover {
  background: #f5f5f5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.product-list-section {
  flex: 1;
  padding: 20px; 
  max-width: 100vw; 
  box-sizing: border-box; 
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  width: 100%;
  box-sizing: border-box; 
}


.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 220px;
  flex: 1;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
}

.product-price {
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 5px 0;
  font-weight: 700;
  margin-top: 10px;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667;
  margin-top: auto;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    align-items: center;  
  }

  .product-item {
    width: 100%;   
    max-width: 300px;    
    margin-bottom: 20px;  
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}



.sort-options select {
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid transparent;
  background-color: #707070;
  color: #e7c084;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.sort-options select:hover,
.sort-options select:focus {
  border-color: #C99C5A;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 20px;
  padding: 0 10px; 
}

.pagination-controls button {
  padding: 10px 20px;
  margin: 0 8px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, #C99C5A, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
  min-width: 100px;
  text-align: center;
}

.pagination-controls button:hover {
  background: linear-gradient(145deg, #C4A77D, #C99C5A);
  transform: scale(1.05);
}

.pagination-controls button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-controls span {
  font-size: 16px;
  margin: 0 10px;
  color: #333;
  font-weight: 600;
}


@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }
  .pagination-controls {
    flex-direction: column; 
  }

  .pagination-controls button {
    min-width: auto; 
    
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #c99c5a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
